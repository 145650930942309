















































































































import moment from 'moment'
import { Component, Ref, Vue } from 'vue-property-decorator'
import ListView from '@/components/ListView.vue'
import ShopAutocomplete from '@/components/ShopAutocomplete.vue'
import DateSelect from '@/components/DateSelect.vue'
import CSVExportBtn from '@/components/CSVExport.vue'

import { ReservationLog, ReservationLogUpdatedField } from '@/types/reservation'

@Component({
  components: {
    ListView,
    ShopAutocomplete,
    DateSelect,
    CSVExportBtn,
  },
})
export default class ReserveLogList extends Vue {
  filterDefinition = {
    userType: { type: String, default: null },
    shop: { type: String, default: null },
    isDeleted: { type: Boolean, default: null },
    dateFrom: { type: String, default: null },
    dateTo: { type: String, default: null },
    updatedFrom: { type: String, default: null },
    updatedTo: { type: String, default: null },
  }
  @Ref() readonly listView!: ListView

  get staffLoggedIn(): boolean {
    return this.$store.getters.staffLoggedIn
  }
  get csvHeaders() {
    return CSVHeaders
  }
  get csvFetch() {
    return () => this.$api.reservations().list(this.listView.query)
  }

  get headers() {
    return [
      { text: '更新日時', value: 'created' },
      { text: '更新者', value: 'editor.username' },
      { text: '更新箇所', value: 'updatedFields' },

      { text: '予約ID', value: 'data.id', width: 80 },
      { text: '削除済', value: 'data.isRemoved', width: 80 },

      { text: '店コード', value: 'data.shopCode', width: 60 },
      { text: '店舗', value: 'data.shopName', width: 200 },
      { text: '来店日', value: 'data.date', width: 100 },
      { text: '来店時間', value: 'data.time', width: 80 },
      { text: '状態', value: 'data.stateText', width: 100 },

      { text: 'お客さま番号', value: 'data.customerCode', width: 100 },
      { text: 'アプリID', value: 'data.userEmail' },
      { text: '予約者氏名', value: 'data.fullNameKana' },
      { text: '予約経路', value: 'data.reservingWayText', width: 150 },
    ]
  }
  datetime(val: string) {
    if (!val) return val
    return moment(val).format('YYYY-MM-DD HH:mm')
  }
  updatedFields(fields: ReservationLogUpdatedField[]) {
    if (fields.length === 0) return '新規作成'
    return fields
      .map(x => `${x.label}: ${x.oldText} -> ${x.newText}`)
      .join(', ')
  }

  filter: null | Record<string, any> = null

  // @Watch('$route.query')
  // onQueryChanged() {
  //   this.query = filterUtil.parseQueryDict(this.$route.query)
  // }

  updateQuery() {
    if (this.filter !== null) this.listView.updateQuery(this.filter)
  }
}

const extractTime = (value: string | undefined) =>
  value ? moment(value).format('HH:mm') : ''
const extractDate = (value: string | undefined) =>
  value ? moment(value).format('YYYY-MM-DD') : ''

const prevValue = (fieldName: string) => (item: ReservationLog) => {
  const found: any = item.updatedFields?.find(x => x.name == fieldName)
  if (found) {
    if (Array.isArray(found.oldText)) return found.oldText[1]
    return found.oldText
  }
  return (
    item.data[fieldName + 'Text'] ||
    item.data[fieldName + 'Name'] ||
    item.data[fieldName]
  )
}
const CSVHeaders = [
  { key: 'editor.username', label: '更新者' },

  { key: 'data.id', label: '予約ID' },
  { key: 'data.isRemoved', label: '削除フラグ' },

  { key: 'data.shopCode', label: '店舗コード' },
  { key: 'data.shopName', label: '店舗名' },
  { key: 'data.date', label: '来店日' },
  { key: 'data.time', label: '来店時間' },

  { key: 'data.familyName', label: '姓' },
  { key: 'data.givenName', label: '名' },
  { key: 'data.familyNameKana', label: 'セイ' },
  { key: 'data.givenNameKana', label: 'メイ' },

  { key: 'data.userEmail', label: 'アプリID' },
  { key: 'data.customerCode', label: 'お客様番号' },
  { key: 'data.phoneNumber', label: '電話番号' },

  { key: 'data.reservingWayText', label: '予約経路' },
  { key: 'data.menuName', label: 'メニュー' },
  { key: 'data.stateText', label: '来店ステータス' },

  // { key: 'sumVisited', label: '来店回数' },
  { key: 'data.attendedStaffCode', label: '予約対応スタッフコード' },
  { key: 'data.attendedStaffName', label: '予約対応スタッフ名' },
  {
    key: 'data.operationHistory.treatmentStaffCode',
    label: '施術対応スタッフコード',
  },
  {
    key: 'data.operationHistory.treatmentStaffName',
    label: '施術対応スタッフ名1',
  },
  {
    key: 'data.operationHistory.dyeingStaffCode1',
    label: '塗布対応スタッフコード1',
  },
  {
    key: 'data.operationHistory.dyeingStaffName1',
    label: '塗布対応スタッフ名1',
  },
  {
    key: 'data.operationHistory.dyeingStaffCode2',
    label: '塗布対応スタッフコード2',
  },
  {
    key: 'data.operationHistory.dyeingStaffName2',
    label: '塗布対応スタッフ名2',
  },
  {
    key: 'data.operationHistory.shampooStaffCode',
    label: 'シャンプー対応スタッフコード',
  },
  {
    key: 'data.operationHistory.shampooStaffName',
    label: 'シャンプー対応スタッフ名',
  },
  {
    key: (item: ReservationLog) =>
      item.updatedFields
        ?.map(x => `${x.label}: ${x.oldText} -> ${x.newText}`)
        .join(', '),
    label: '変更箇所',
  },
  {
    key: prevValue('isRemoved'),
    label: '変更前 削除フラグ',
  },
  {
    key: prevValue('shop'),
    label: '変更前 店舗',
  },
  {
    key: prevValue('date'),
    label: '変更前 来店日',
  },
  {
    key: prevValue('time'),
    label: '変更前 来店時間',
  },
  {
    key: prevValue('state'),
    label: '変更前 来店ステータス',
  },
  {
    key: prevValue('reservedByd'),
    label: '変更前 予約経路',
  },
  // {
  //   key: prevValue('note'),
  //   label: '変更前 備考',
  // },
  // {
  //   key: prevValue('requestColor'),
  //   label: '変更前 要望',
  // },
  // {
  //   key: prevValue('menu'),
  //   label: '変更前 メニュー',
  // },
  // {
  //   key: prevValue('treatment'),
  //   label: '変更前 treatment',
  // },
  // {
  //   key: prevValue('scalpCare'),
  //   label: '変更前 頭皮ケア',
  // },
  // {
  //   key: prevValue('additionalDyestuff'),
  //   label: '変更前 薬剤追加',
  // },
  {
    key: prevValue('attendedStaff'),
    label: '変更前 予約対応スタッフ',
  },
  {
    key: prevValue('staff'),
    label: '変更前 施術予定スタッフ',
  },
  {
    key: prevValue('shampooDyestuff'),
    label: '変更前 シャンプー予定スタッフ',
  },
  // {
  //   key: 'operationHistory.scalpProtectiveAgentText',
  //   label: '頭皮保護剤',
  // },
  // {
  //   key: 'treatmentName',
  //   label: 'トリートメント',
  // },
  // {
  //   key: (item: any) => extractDate(item.ratingPushNotified),
  //   label: 'レビューPush日',
  // },
  // {
  //   key: (item: any) => extractTime(item.ratingPushNotified),
  //   label: 'レビューPush時間',
  // },
  // {
  //   key: 'rating.point',
  //   label: 'レビュー点数',
  // },
  // {
  //   key: 'rating.freeComment',
  //   label: 'レビューコメント',
  // },
  // {
  //   key: (item: Reservation) => extractDate(item.rating?.created),
  //   label: 'レビュー投稿日',
  // },
  // {
  //   key: (item: Reservation) => extractTime(item.rating?.created),
  //   label: 'レビュー投稿時刻',
  // },

  // { key: (item: Reservation) => extractDate(item.createdAt), label: '作成日' },
  // {
  //   key: (item: Reservation) => extractTime(item.createdAt),
  //   label: '作成時刻',
  // },
  { key: (item: ReservationLog) => extractDate(item.created), label: '更新日' },
  {
    key: (item: ReservationLog) => extractTime(item.created),
    label: '更新時刻',
  },
]
