

























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { StateChanger } from 'vue-infinite-loading'
import FilterUtil from '@/filter-util'
import { Dictionary } from 'vue-router/types/router'

export interface ListHeader {
  text: string
  value: string
  width?: number
  fixed?: string
}
export interface FechData {
  (params: Record<string, unknown>): Promise<{
    results: any[]
    next: null | string
  }>
}
export interface GetTotalCount {
  (params: Record<string, unknown>): Promise<number>
}
export type FilterDefinition = Record<
  string,
  { type: typeof Number | typeof String | typeof Array; default: any }
>

export type ConvertFetchParams = (
  params: Record<string, any>
) => Record<string, any>

@Component({
  components: {},
})
export default class ListView extends Vue {
  @Prop({ required: true }) readonly headers!: ListHeader[]
  @Prop({ type: Function, required: true }) readonly fetchData!: FechData
  @Prop({ type: [Function, Number], default: null }) readonly totalCount!:
    | number
    | GetTotalCount
  @Prop({ type: Object, default: null })
  readonly filterDefinition!: FilterDefinition
  @Prop({ type: Function, default: null })
  readonly convertFetchParams!: null | ConvertFetchParams

  items: any[] = []
  next: null | string = null

  cacheTotalCount: null | number = null

  query: null | any = null
  _filterUtil: null | FilterUtil = null
  reloadAt: null | number = null

  get identifier() {
    if (this.reloadAt) return JSON.stringify(this.query) + String(this.reloadAt)
    return JSON.stringify(this.query)
  }

  @Watch('identifier')
  onIdentifierChanged() {
    this.items = []
    this.next = null
    this.cacheTotalCount = null
    this.onTotalCountChanged()
  }

  @Watch('totalCount')
  async onTotalCountChanged() {
    if (this.totalCount === null) {
      this.cacheTotalCount = null
    } else if (typeof this.totalCount === 'function') {
      this.cacheTotalCount = await this.totalCount(this.fetchParams)
    } else {
      this.cacheTotalCount = this.totalCount
    }
  }

  @Watch('$route.query')
  onQueryChanged(query: Record<string, unknown>) {
    this.query = this._filterUtil?.parseQueryDict(query) || null
  }

  get fetchParams() {
    if (this.convertFetchParams)
      return this.convertFetchParams(Object.assign({}, this.query || {}))
    return this.query || {}
  }

  created() {
    this._filterUtil = this.filterDefinition
      ? new FilterUtil(this.filterDefinition)
      : null
    if (this._filterUtil) {
      let query = this._filterUtil.parseQueryDict(this.$route.query)
      this.query = query

      this.$emit('initialized-filter', Object.assign(query))
    }
  }
  mounted() {
    this.onTotalCountChanged()
  }

  public updateQuery(value: Record<string, unknown>) {
    if (this._filterUtil) {
      let query = this._filterUtil.mergeQueryDict(
        this.query,
        value
      ) as Dictionary<string>
      this.$router.push({ query: query })
    }
  }

  public async getTotalCount() {
    if (this.cacheTotalCount !== null) return this.cacheTotalCount
    if (typeof this.totalCount === 'function')
      return await this.totalCount(this.fetchParams)
    return this.totalCount
  }

  async onInfinite($state: StateChanger) {
    try {
      let { results, next } = await (this.next
        ? this.$api.http.get(this.next)
        : this.fetchData(this.fetchParams))
      this.items = this.items.concat(results)
      this.next = next
      if (this.items.length) $state.loaded()
      if (!this.next) $state.complete()
    } catch (err) {
      $state.error()
    }
  }
  reload() {
    this.reloadAt = new Date().getTime()
  }
}
