





















import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { CursorPaginationResult } from '@/api'

export interface ValueExtrator {
  (item: Record<string, unknown>): string
}
export interface Header {
  label: string
  key: string | ValueExtrator
}

const sleep = (ms: number) =>
  new Promise(resolve => {
    setTimeout(resolve, ms)
  })

@Component({
  components: {},
})
export default class CSVExport extends Vue {
  @Prop({ default: null }) readonly totalCount!:
    | null
    | number
    | (() => Promise<number>)
  @Prop({ default: '' }) readonly filenamePrefix!: string
  @Prop({ required: true })
  readonly fetch!: () => Promise<CursorPaginationResult>

  @Prop({ required: true }) readonly headers!: Header[]
  @Prop({ type: Function, default: null }) transformItems!:
    | null
    | ((items: any[]) => any[])

  isLoading = false
  aborted = false
  fetchedCount = 0
  fetchedTotalCount: number | null = null

  async ensureTotalCount() {
    if (typeof this.totalCount === 'function') {
      this.fetchedTotalCount = await this.totalCount()
    } else {
      this.fetchedTotalCount = this.totalCount
    }
  }

  get progressPercent() {
    let total = this.fetchedTotalCount
    if (total) return Math.floor((this.fetchedCount / total) * 100)
    return null
  }

  async fetchAll() {
    let { results, next } = await this.fetch()
    let items = results
    this.fetchedCount = items.length
    while (!this.aborted && next) {
      try {
        let resp: CursorPaginationResult = await this.$api.http.get(next)
        next = resp.next
        items = items.concat(resp.results)
        this.fetchedCount = items.length
      } catch (err) {
        console.error(err)
        this.$toast.warn(
          `サーバーエラーが発生しました。再接続します。HTTP Status ${err.status}`
        )
        await sleep(2000)
      }
    }
    if (this.transformItems) items = await this.transformItems(items)
    const dquote = new RegExp('"', 'g')
    const nl = new RegExp('\n', 'g')
    /* eslint no-control-regex: "off" */
    // const commaOrNL = new RegExp(',|\n')
    let rows = [this.headers.map(x => x.label)]
    items.forEach(item => {
      rows.push(
        this.headers.map(header => {
          let val = this.transform(item, header)
          if (val === null || val === undefined) return ''
          let str = String(val)
          str = str.replace(dquote, '""') // NOTE: ダブルクオーテーションのエスケープ " => ""
          str = str.replace(nl, ' ') // NOTE: Excelの読み込みでバグるので改行をスペースに変換
          return `"${str}"`
        })
      )
    })
    this.download(
      `${this.filenamePrefix}${moment().format('YYYY-MM-DD')}.csv`,
      rows
    )
  }

  async submit() {
    this.isLoading = true
    this.aborted = false
    try {
      this.ensureTotalCount()
      await this.fetchAll()
    } catch (err) {
      console.error(err)
      this.$toast.error('CSV出力中にエラーが発生しました。')
    }
    this.fetchedCount = 0
    this.fetchedTotalCount = null
    this.isLoading = false
    this.aborted = false
  }

  transform(item: Record<string, unknown>, header: Header) {
    if (typeof header.key === 'function') {
      return header.key(item)
    } else {
      let keys = header.key.split('.')
      let len = keys.length
      let obj: any = item
      for (let idx = 0; idx < len; ++idx) {
        if (!obj) return obj
        if (typeof obj !== 'object') return obj
        obj = obj[keys[idx]]
      }
      return obj
    }
  }

  download(filename: string, rows: any[]) {
    rows = rows.map(row => row.join(','))
    let content = rows.join('\r\n')
    let bom = new Uint8Array([0xef, 0xbb, 0xbf])
    let blob = new Blob([bom, content], { type: 'text/csv' })

    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, filename)
    } else {
      let a = document.createElement('a')
      a.download = filename
      // a.target = '_blank'
      a.href = window.URL.createObjectURL(blob)

      // firefox対策． chromeの場合は append/remove をしなくていいらしい
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }
}
